import { _ } from "vue-underscore";
import store from "@/store/index.js";
import Multiselect from "vue-multiselect";

export default {
  data: () => ({
    content: "",
    backUrl: "/email-campaign",
    emailCampaign: {
      id: 0,
      shooting_time: "",
      rejected_email_domain: "",
      name: "",
      template_id: "",
      description: "",
      audience_id: "",
      audience_total_email_count: 0,
      sender_id: "",
      audience_id: "",
      audience_total_days_count: "",
      New_york_time: "",
      audience_email_per_day_send_count: "",
      isNoDaysCalculated: false,
      selected_email_domains: [],
    },
    audience_total_email_count_for_display: 0,
    saveDisable: false,
    dynamicTemplateList: [],
    Domains: [],
    marketingEmailSenderList: [],
    audienceList: [],
    selectedAudience: null,
    noOfDays: "",
    selectedAudiences: "",
    selectAllText: "Select All",
  }),

  watch: {
    "emailCampaign.shooting_time": function (newVal, oldVal) {
      this.emailCampaign.New_york_time = this.convertToEDT(newVal);
    },
    "emailCampaign.audience_id": function (newVal) {
      const selectedAudience = this.audienceList.find(
        (audience) => audience.id === newVal
      );
      if (selectedAudience) {
        this.selectedAudiences = selectedAudience.agent_audience;
      }
    },
    "emailCampaign.selected_email_domains": {
      handler(newSelectedDomains) {
        this.emailCampaign.audience_total_email_count =
          newSelectedDomains.reduce((total, domain) => {
            return total + (domain.count || 0);
          }, 0);
      },
      deep: true,
    },
  },

  computed: {
    DomainsWithSelectAll() {
      if (this.Domains.length > 0) {
        const selectAllText =
          this.emailCampaign.selected_email_domains.length ===
            this.Domains.length
            ? "Deselect All"
            : "Select All";
        return [{ domain: selectAllText, count: null }, ...this.Domains];
      }
      return [];
    },
  },
  components: { Multiselect },

  methods: {
    customLabel({ domain, count }) {
      return count !== null ? `${domain} - ${count}` : domain;
    },
    validateEmailCampaign() {
      this.emailCampaign.id > 0 ? this.update() : this.add();
    },
    update() {
      let _vm = this;
      store.state.isLoaderShow = true;
      _vm.saveDisable = true;
      let sender = this.marketingEmailSenderList.find(
        (sender) => sender.id === this.emailCampaign.sender_id
      );
      if (sender) {
        this.emailCampaign.sender_name = sender.name;
        this.emailCampaign.sender_email = sender.email;
      }
      this.emailCampaign.tested = false;
      this.emailCampaign.running = false;
      this.axios
        .post("/campaigns/" + this.emailCampaign.id, {
          ...this.emailCampaign,
          _method: "PATCH",
        })
        .then(function (response) {
          _vm.emailCampaign = response.data.data;
          _vm.$router.push(_vm.backUrl);
          store.state.isLoaderShow = false;
          _vm.saveDisable = false;
        })
        .catch(function () {
          store.state.isLoaderShow = false;
          _vm.saveDisable = false;
        });
    },
    add() {
      let _vm = this;
      store.state.isLoaderShow = true;
      _vm.saveDisable = true;
      let sender = this.marketingEmailSenderList.find(
        (sender) => sender.id === this.emailCampaign.sender_id
      );
      if (sender) {
        this.emailCampaign.sender_name = sender.name;
        this.emailCampaign.sender_email = sender.email;
      }
      this.axios
        .post("/campaigns", _vm.emailCampaign)
        .then(function (response) {
          _vm.$router.push(_vm.backUrl);
          store.state.isLoaderShow = false;
          _vm.saveDisable = false;
        })
        .catch(function () {
          store.state.isLoaderShow = false;
          _vm.saveDisable = false;
        });
    },
    handleSelectAll(selectedOptions) {
      const selectAllOption = selectedOptions.find(
        (option) => option.domain === "Select All"
      );
      const deselectAllOption = selectedOptions.find(
        (option) => option.domain === "Deselect All"
      );

      if (selectAllOption) {
        this.emailCampaign.selected_email_domains = [...this.Domains];
      } else if (deselectAllOption) {
        this.emailCampaign.selected_email_domains = [];
      }
    },
    getEmailCount(id) {
      let _vm = this;
      store.state.isLoaderShow = true;

      let url = "/audience-emails-count/" + id;
      // if (_vm.emailCampaign.rejected_email_domain !== "") {
      //   url +=
      //     "?rejected_email_domain=" + _vm.emailCampaign.rejected_email_domain;
      // }

      this.axios
        .get(url)
        .then(function (response) {
          _vm.Domains = response.data.data;
          _vm.Domains.forEach((e) => {
            _vm.audience_total_email_count_for_display += e.count;
          });
          // _vm.emailCampaign.audience_total_email_count = response.data.data;
          _vm.$forceUpdate();
          store.state.isLoaderShow = false;
        })
        .catch(function (error) {
          // Handle error
          store.state.isLoaderShow = false;
        });
    },
    calculateNoDays() {
      let _vm = this;
      _vm.emailCampaign.audience_total_days_count = Math.ceil(
        _vm.emailCampaign.audience_total_email_count /
        _vm.emailCampaign.audience_email_per_day_send_count
      );
    },
    getDetail() {
      let _vm = this;
      this.axios
        .get("/campaigns/" + this.$route.params.id)
        .then(function (response) {
          _vm.emailCampaign = response.data.data;
          _vm.audienceList;
          if (_vm.emailCampaign.shooting_time) {
            _vm.emailCampaign.New_york_time = _vm.convertToEDT(
              _vm.emailCampaign.shooting_time
            );
          }
        })
        .catch(function () { });
    },
    getDynamicTemplate() {
      let _vm = this;
      this.axios
        .get("/get-campaign-templates", _vm.dynamicTemplateList)
        .then(function (response) {
          _vm.dynamicTemplateList = response.data.data;
        })
        .catch(function () { });
    },
    getAudience() {
      let _vm = this;
      this.axios
        .get("/audience", _vm.audienceList)
        .then(function (response) {
          _vm.audienceList = response.data.data;
        })
        .catch(function () { });
    },
    getNoPerDay() {
      if (
        this.emailCampaign.audience_total_days_count &&
        this.emailCampaign.audience_total_days_count !== 0
      ) {
        this.emailCampaign.audience_email_per_day_send_count = Math.ceil(
          this.emailCampaign.audience_total_email_count /
          this.emailCampaign.audience_total_days_count
        );
      } else {
        this.emailCampaign.audience_email_per_day_send_count = 0;
      }
    },
    getMarketingEmailSender() {
      let _vm = this;
      this.axios
        .get("/marketing-email-sender", _vm.marketingEmailSenderList)
        .then(function (response) {
          _vm.marketingEmailSenderList = response.data.data;
        })
        .catch(function () { });
    },
    convertToEDT(selectedTime, selectedTimeZone) {
      const [hours, minutes] = selectedTime.split(":").map(Number);
      const selectedDate = new Date();
      selectedDate.setUTCHours(hours);
      selectedDate.setUTCMinutes(minutes);
      const selectedTimeZoneOffset =
        selectedDate.getTimezoneOffset() * 60 * 1000;
      const edtOffset = -4 * 60 * 60 * 1000; // Convert hours to milliseconds
      const edtTime = new Date(
        selectedDate.getTime() + selectedTimeZoneOffset + edtOffset
      );
      return edtTime.toISOString().slice(11, 16);
    },
  },
  mounted() {
    let _vm = this;
    if (_vm.$route.params.id > 0) {
      _vm.getDetail();
    }
    _vm.getDynamicTemplate();
    _vm.getAudience();
    _vm.getMarketingEmailSender();
  },
};
